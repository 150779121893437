/** @jsx jsx */
import { jsx, Container, Text, Box } from 'theme-ui';
import React from 'react';
import { Link, PageProps } from 'gatsby';

const NotFound: React.FC<PageProps> = () => (
  <Container pt={['5rem', '5rem', '10rem']}>
    <Box
      sx={{
        maxWidth: '48.25rem',
      }}
    >
      <Text as="h1" variant="h2" mb={['2.25rem', '2.25rem', '5rem']}>
        Error 404
      </Text>
      <Text as="p" variant="bodySmall" mb={['1.5rem', '1.5rem', '3.625rem']}>
        Oops, sorry we can’t find that page! Either something went wrong or the
        page doesn’t exist anymore.
      </Text>
      <Link
        to="/"
        sx={{
          variant: 'links.underlined',
        }}
      >
        Head to home page
      </Link>
    </Box>
  </Container>
);

export default NotFound;
